<template>
  <v-col class="px-0 py-0">
    <v-row no-gutters align="center">
      <div
        style="
          width: 12px;
          height: 12px;
          background: #144fa9;
          border-radius: 50%;
        "
      />
      <h2
        style="
          color: var(--Black, #1b1b1b);
          font-family: 'MacPaw Fixel';
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-left: 8px;
        "
      >
        {{ "our_routes_section" | localize }}
      </h2>
      <v-row
        no-gutters
        justify="end"
        align="center"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <button class="showAllBtn" @click="$router.push('/routes')">
          {{ "show_all_btn" | localize }}
        </button>
      </v-row>
    </v-row>
    <v-row
      no-gutters
      align="center"
      :style="
        $vuetify.breakpoint.smAndDown ? 'margin-top: 20px;' : 'margin-top: 40px'
      "
    >
      <v-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        v-for="(route,index) in routeList"
        :key="route.id"
        style="margin-bottom: 20px"
        :style="
          !$vuetify.breakpoint.smAndDown
            ? index % 2 == 1
              ? 'padding-right: 10px;'
              : 'padding-left: 10px'
            : ''
        "
      >
        <route-card :route="route" />
      </v-col>
    </v-row>
    <submit-outline-button
      @close="$router.push('/routes')"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      {{ "show_all_btn" | localize }}</submit-outline-button
    >
  </v-col>
</template>

<script>
import routesService from "../../../../requests/Admin/routesService";
import SubmitOutlineButton from "../../../UI/Buttons/submitOutlineButton.vue";
import routeCard from "../../../UI/Cards/routeCard.vue";
export default {
  components: { routeCard, SubmitOutlineButton },
  data: () => ({
    routeList: [],
  }),
  mounted() {
    this.getRoutes();
  },
  methods: {
    async getRoutes() {
      await routesService.getRoutesForMain().then((res) => {
        if (res.status == "Success") {
          this.routeList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.showAllBtn {
  color: var(--Black, #1b1b1b);
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid rgba(20, 79, 169, 0.5);
  width: 180px;
  height: 40px;
  display: grid;
  place-items: center;
}
</style>