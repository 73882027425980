<template>
  <div class="cardBackground">
    <v-row no-gutters align="center">
      <img
        :src="route?.image?.path"
        :width="$vuetify.breakpoint.smAndDown ? '60px' : '80px'"
        :height="$vuetify.breakpoint.smAndDown ? '53px' : '71px'"
        alt="route img"
        style="border-radius: 10px"
      />
      <v-col cols="7" xl="9" lg="9" md="9" sm="8">
        <v-row
          no-gutters
          :align="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
        >
          <v-col
            class="py-0 px-2"
            :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
            cols="2"
            style="margin-left: 12px"
          >
            <div
              style="
                border: 2px solid #122b3e;
                border-radius: 50%;
                width: 16px;
                height: 16px;
              "
            ></div>
            <div
              style="
                width: 2px;
                height: 41px;
                border: 2px dashed #08589566;
                margin-left: 5.5px;
              "
              :style="$vuetify.breakpoint.smAndDown ? 'height: 23px' : ''"
            ></div>
            <div
              style="
                border: 2px solid #085895;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                display: grid;
                place-items: center;
              "
            >
              <div
                style="
                  border-radius: 50%;
                  width: 10px;
                  height: 10px;
                  background-color: #085895;
                "
              ></div>
            </div>
          </v-col>
          <v-col
            class="px-0 py-0"
            :style="$vuetify.breakpoint.smAndDown ? 'padding-left: 24px' : ''"
          >
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 1.4px;
                margin: 0px;
              "
            >
              {{ route?.departure?.translations?.name }},
              <span style="color: #4c5d6c; font-weight: 400">{{
                route?.departure?.country?.translations?.name
              }}</span>
            </p>
            <p
              style="
                color: #1b1b1b;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 1.4px;
                margin: 40px 0px 0px 0px;
              "
              :style="
                $vuetify.breakpoint.smAndDown ? 'margin: 23px 0px 0px 0px' : ''
              "
            >
              {{ route?.destination?.translations?.name }},
              <span style="color: #4c5d6c; font-weight: 400">{{
                route?.destination?.country?.translations?.name
              }}</span>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-row no-gutters justify="end">
        <div
          class="iconBackground"
          @click="$router.push(`/route/${route.slug}/${route.id}`)"
        >
          <div class="swipeBtn" />
        </div>
      </v-row>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    route: { require: true },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  .cardBackground {
    border-radius: 10px;
    background: linear-gradient(90deg, #000 -1.9%, rgba(0, 0, 0, 0) -1.9%), #fff;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
    padding: 16px !important;
  }
  .iconBackground {
    width: 48px;
    height: 48px;
    background: #e7edf6;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1023px) {
  .cardBackground {
    border-radius: 10px;
    background: linear-gradient(90deg, #000 -1.9%, rgba(0, 0, 0, 0) -1.9%), #fff;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
    padding: 16px !important;
  }
  .iconBackground {
    width: 40px;
    height: 40px;
    background: #e7edf6;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
}
.iconBackground:hover {
  background: #00318b;
}
.iconBackground:hover .swipeBtn {
  background-color: #fafafa;
}
.swipeBtn {
  width: 10px;
  height: 18px;
  background-color: black;
  -webkit-mask: url("../../../assets/img/iconsSvg/swiperRightIcon.svg")
    no-repeat center;
  mask: url("../../../assets/img/iconsSvg/swiperRightIcon.svg") no-repeat center;
}
</style>